import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Hero from "../components/hero"
import FeatureDisplay, { FeatureDisplayProps } from "../components/feature-display"
// @ts-ignore
import ticksterHome from "../images/tickster-home.jpg"
// @ts-ignore
import saveAllImage from "../images/tickster-all.jpg"
// @ts-ignore
import notificationsImage from "../images/tickster-results.jpg"

const features: FeatureDisplayProps[] = [
  {
    heading: "Save all your tickets!",
    description: `Never lose a ticket. All tickets are saved
    and can be accessed from any device.`,
    image: saveAllImage
  },
  {
    heading: `Get notifications for draws and winnings!`,
    description: `Get notifications for all draws and notifications for
    any winning ticket. Drawn numbers are highlighted and the winning value
    is displayed.`,
    image: notificationsImage
  }
]

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero
      content={
        [
          'Track your numbers',
          'Get notifications',
          'Free!'
        ]
      }
      graphicSrc={ticksterHome}
    />
    <Container>
      {
        features.map((feature) => (
          FeatureDisplay(feature)
        ))
      }
    </Container>
  </Layout>
)

export default IndexPage
