import * as React from "react"
import * as PropTypes from "prop-types"
import Container from "./container"
// @ts-ignore
import FeatureStyles from "../styles/feature-display.module.scss"

type FeatureDisplayProps = {
  heading: string,
  description: string,
  image: string
}

const FeatureDisplay = ({heading, description, image}: FeatureDisplayProps) => {
  return(
    <Container>
      <div className={FeatureStyles.feature}>
        <div className={FeatureStyles.feature__body}>
          <div className={FeatureStyles.feature__graphic}>
            <img
              className={FeatureStyles.feature__image}
              src={image}
              alt={`feature image`}
            />
          </div>
          <div className={FeatureStyles.feature__description}>
            <div className={FeatureStyles.feature__heading}>
              <h2>{heading}</h2>
            </div>
            {description}
          </div>
        </div>
      </div>
    </Container>
  )
}

FeatureDisplay.propType = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
}

export default FeatureDisplay
export type { FeatureDisplayProps }