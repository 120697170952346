import * as React from "react"
import * as PropTypes from "prop-types"
// @ts-ignore
import HeroStyles from "../styles/hero.module.scss"
import Container from "./container"

type HeroProps = {
  content: string[],
  graphicSrc?: string
}

const Hero = ({ content, graphicSrc}: HeroProps) => {
  const title = content.map((line) => (
    <h1>{line}</h1>)
  )
  let imageClasses = HeroStyles.hero__graphic
  if (graphicSrc == null) {
    imageClasses = `${imageClasses} ${HeroStyles.isGone}`
  }
  return (
    <header className={HeroStyles.hero_wrapper}>
      <Container>
        <div className={HeroStyles.hero}>
          <div className={HeroStyles.hero__description}>
            {title}
          </div>
          <div className={imageClasses}>
            <img
              className={HeroStyles.hero__image}
              src={graphicSrc}
              alt={`page heading`}
            />
          </div>
        </div>
      </Container>
    </header>
  )
}

Hero.propTypes = {
  content: PropTypes.array.isRequired,
  graphicSrc: PropTypes.string
}

export default Hero